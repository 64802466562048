:root {
  --primary: #1c262f;
  --primary-light: #212d37;
  --secondary: #58a8c5;
  --tertiary: #58a8c5;
  --gradient: linear-gradient(to right, #58a8c5, #58a8c5);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

button {
  font-family: sans-serif;
}

p,
ul,
h4 {
  margin: 0;
  padding: 0;
}

a {
  color: white;
  text-decoration: none;
}

li {
  list-style-type: none;
}

/* Section Background */
.about,
.services,
.plans,
.work,
.contact {
  height: 110vh;
  position: relative;
}
.home {
  height: 70vh;
  position: relative;
}

.services,
.work,
.contact,
.testimonial,
.footer {
  background-color: var(--primary);
}

.about,
.plans,
.company,
.newsletter,
.location {
  background-color: var(--primary-light);
}

.bottom {
  background-color: black;
}

/* Gradient Border And Background On Icons */
.home_text,
.home .fas,
.plans .far,
.information .fas,
.work .fas,
.services .fas,
.location .far,
.location .fas {
  padding: 19px 0;
  background-color: #58a8c5 !important ;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.decoration {
  text-decoration: none !important;
}

.navbar-nav a:hover,
.footer a:hover,
.footer .fab:hover {
  cursor: pointer;
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/**********************/
/*     Navigation     */
/**********************/
.navbar {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.5rem;
  background-color: var(--primary);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.025);
}

/* .navbar .navbar-brand {
  padding-top: 0.65rem;
  padding-bottom: 0.25rem;
} */

/* .navbar .logo-image img {
  width: 100px;
  height: 50px;
} */

.navbar .logo-text {
  color: #fff;
  font-weight: 500;
  line-height: 1rem;
  font-size: 3rem;
  text-decoration: none;
}

.offcanvas-collapse {
  position: fixed;
  top: 2.75rem; /* adjusts the height between the top of the page and the offcanvas menu */
  bottom: 0;
  left: 100%;
  width: 100%;
  overflow-y: auto;
  visibility: hidden;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: var(--primary);
  transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.offcanvas-collapse.open {
  visibility: visible;
  transform: translateX(-100%);
}
/* 
.navbar .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
} */

.navbar .dropdown-menu {
  border: none;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: var(--primary);
}

.navbar .dropdown-item {
  color: #eee;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  padding-top: 0.625rem;
  text-decoration: none;
  padding-bottom: 0.625rem;
}

.navbar .dropdown-item:hover {
  background-color: var(--primary);
}

.navbar .dropdown-divider {
  width: 100%;
  height: 1px;
  border: none;
  margin: 0.5rem auto 0.5rem auto;
  background-color: var(--primary-light);
}

.navbar .nav-item .nav-link {
  color: #eee;
  text-decoration: none;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  transition: all 0.2s ease;
}

.navbar .fa-stack {
  width: 2em;
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

.navbar .fa-stack-2x {
  background-color: #58a8c5;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.2s ease;
}

.navbar .fa-stack-1x {
  color: #ffffff;
  transition: all 0.2s ease;
}

.navbar .fa-stack:hover .fa-stack-2x {
  color: var(--primary);
}

.navbar .fa-stack:hover .fa-stack-1x {
  color: var(--primary);
}

.navbar .navbar-toggler {
  padding: 0;
  border: none;
  font-size: 1.25rem;
}

/****************/
/*     Home     */
/****************/
.home {
  background-image: url(./components/images/home.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.clip1{
  clip-path: polygon(0 0, 100% 0, 100% 87%, 0 100%);

}
.clip2{
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 87%);

}
.slog {
  font-family: "Great Vibes", cursive;
  font-family: "Pinyon Script", cursive;
  font-family: "Satisfy", cursive;
}
.para {
  width: 50%;
}

.para-light {
  opacity: 0.8;
}

/***********************/
/*     Information     */
/***********************/
.information .container-fluid .row div:first-child {
  background-color: var(--primary);
}

.information .container-fluid .row div:last-child {
  background-color: rgb(255, 255, 255);
}

.information .container-fluid .row div:nth-child(2) {
  background-color: var(--primary-light);
}

/******************/
/*     Button     */
/******************/
.btn {
  color: rgb(244, 240, 240);
  border-radius: 0;
  border-width: 2px;
  padding: 10px 30px;
  border-image-slice: 1;
  background-image: none;
  border-image-source: var(--gradient);
  box-shadow: 5px 5px 0px 0px var(--secondary);
}

.btn:hover {
  color: white;
  box-shadow: none;
  border-radius: 0;
  padding: 10px 30px;
  transform: translate(5px, 5px);
}

/* Email - Newsletter Button */
.btn-secondary {
  color: rgb(255, 255, 255);
  border-width: 2px;
  padding: 10px 30px;
  border-image-slice: 1;
  background-image: none;
  background: transparent;
  border-image-source: var(--gradient);
}

.btn-secondary:hover {
  color: black;
  padding: 10px 30px;
  background-image: var(--gradient);
}

/* Other Pages Button */
.btn-tertiary {
  color: black;
  border-radius: 0;
  border-width: 2px;
  padding: 10px 30px;
  border-image-slice: 1;
  background-image: none;
  background: transparent;
  border-image-source: var(--gradient);
  box-shadow: 5px 5px 0px 0px var(--secondary);
}

.btn-tertiary:hover {
  color: black;
  box-shadow: none;
  border-radius: 0;
  padding: 10px 30px;
  transform: translate(5px, 5px);
}

/********************/
/*     Services     */
/********************/
.services .card {
  padding: 20px;
  border-radius: 0;
  border: 2px solid white;
}

.services .card:hover {
  border: 10px solid;
  transform: scale(1.01);
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: var(--gradient);
  box-shadow: 5px 5px 0px 0px #1e4d91;
}

/*****************/
/*     Plans     */
/*****************/
.plans .card {
  padding: 20px;
  border: 2px solid white;
}

/************************/
/*     Testimonials     */
/************************/
.slider-1 {
  padding-top: 8.25rem;
  padding-bottom: 8.5rem;
}

.slider-1 .section-title {
  text-align: center;
}

.slider-1 .h2-heading {
  text-align: center;
  margin-bottom: 3rem;
}

.testimonial-card {
  border: 5px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: var(--gradient);
}

.slider-1 .slider-container {
  position: relative;
}

.slider-1 .swiper-container {
  width: 96%;
  position: static;
}

.slider-1 .swiper-button-prev,
.slider-1 .swiper-button-next {
  color: var(--primary);
}

.slider-1 .swiper-button-prev:focus,
.slider-1 .swiper-button-next:focus {
  /* even if you can't see it chrome you can see it on mobile device */
  outline: none;
}

.slider-1 .swiper-button-prev {
  left: -14px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23707375'%2F%3E%3C%2Fsvg%3E");
  background-size: 18px 28px;
}

.slider-1 .swiper-button-next {
  right: -14px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23707375'%2F%3E%3C%2Fsvg%3E");
  background-size: 18px 28px;
}

.slider-1 .card {
  border: none;
  position: relative;
  background-color: transparent;
}

.slider-1 .card-image {
  width: 140px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-bottom: 1.25rem;
}

.slider-1 .card-body {
  padding: 0;
}

.slider-1 .testimonial-text {
  margin-bottom: 0.75rem;
}

.slider-1 .testimonial-author {
  margin-bottom: 0;
  color: #252c38;
}

.slider-1 .avatar {
  width: 70px;
  height: 80px;
  border-radius: 50%;
}

/* Dropdown Menu */
.navbar .dropdown .dropdown-menu {
  animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@-webkit-keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/**********************/
/*     Newsletter     */
/**********************/
.form-control-input,
.form-control-textarea {
  width: 100%;
  appearance: none;
  border-radius: 4px;
  font-size: 1.875rem;
  line-height: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 0.775rem;
  padding-bottom: 0.775rem;
}

/******************************/
/*     Back To Top Button     */
/******************************/
#myBtn {
  z-index: 99;
  right: 20px;
  width: 52px;
  height: 52px;
  bottom: 20px;
  border: none;
  outline: none;
  display: none;
  position: fixed;
  cursor: pointer;
  border-radius: 50%;
  background-color: #323137;
}

#myBtn:hover {
  background-color: #0f0f11;
}

#myBtn img {
  width: 18px;
  margin-left: 0.125rem;
  margin-bottom: 0.25rem;
}

/***********************/
/*     Extra Pages     */
/***********************/
.ex-header {
  padding-top: 8.5rem;
  padding-bottom: 4rem;
  background-color: var(--primary);
}

.ex-header h1 {
  color: #fff;
}

.ex-basic-1 .list-unstyled .fas {
  font-size: 3.375rem;
  line-height: 1.625rem;
}

.ex-basic-1 .list-unstyled .flex-grow-1 {
  margin-left: 0.5rem;
}

.ex-basic-1 .text-box {
  padding: 1.25rem 1.25rem 0.5rem 1.25rem;
  background-color: #f7f9fd;
}

.ex-cards-1 .card {
  border: none;
  background-color: transparent;
}

.ex-cards-1 .card .fa-stack {
  width: 2em;
  font-size: 3.125rem;
}

.ex-cards-1 .card .fa-stack-2x {
  color: var(--secondary);
}

.ex-cards-1 .card .fa-stack-1x {
  width: 2em;
  color: #ffffff;
  font-weight: 700;
  line-height: 2.125rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 {
  margin-left: 2.25rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 h5 {
  margin-top: 0.125rem;
  margin-bottom: 0.5rem;
}

/*************************/
/*     Media Queries     */
/*************************/
@media only screen and (max-width: 1024px) {
  .services,
  .work,
  .testimonial,
  .about,
  .contact,
  .plans {
    height: 100%;
  }
}

@media (min-width: 992px) {
  .slider-1 .swiper-container {
    width: 30%;
  }

  .slider-1 .swiper-button-prev {
    left: -16px;
    width: 22px;
    background-size: 22px 34px;
  }

  .slider-1 .swiper-button-next {
    right: -16px;
    width: 22px;
    background-size: 22px 34px;
  }

  /* Navigation */
  .navbar {
    box-shadow: none;
    transition: all 0.2s;
    padding-top: 1.75rem;
    background-color: transparent;
  }

  .navbar.top-nav-collapse {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: var(--primary);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.025);
  }

  .offcanvas-collapse {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    width: auto;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    overflow-y: visible;
    visibility: visible;
  }

  .offcanvas-collapse.open {
    transform: none;
  }

  .navbar .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  .navbar .nav-item .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .navbar .dropdown-menu {
    padding-top: 0.75rem;
    padding-bottom: 0.875rem;
    box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.08);
  }

  .navbar .dropdown-divider {
    width: 90%;
  }

  .navbar .social-icons {
    margin-left: 0.5rem;
  }

  .navbar .fa-stack {
    margin-right: 0;
    margin-left: 0.25rem;
  }
}

@media only screen and (max-width: 540px) {
  h1 {
    font-size: 30px !important;
  }

  .para {
    width: 100%;
  }

  .swiper-container {
    width: 92%;
  }
}

.slick-slider {
  padding: 0px !important;
  margin: 0px !important;
}
.slick-track {
  padding: 0px !important;
}

.desBackground {
  background: #004542
    url(https://www.dreamyachtcharter.com/wp-content/themes/dyc/assets/dist/theme/img/bg-top-destinations.png)
    50% 50% no-repeat;
  background-size: cover;
}

figure {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  border-radius: 10px;
  overflow: hidden;
}
figure:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
figure:hover h1 {
  opacity: 0;
  transform: scale(0.7);
}
figure:hover img {
  transform: scale(1.25);
}
figure:hover figcaption {
  bottom: 0;
}
figure h1 {
  position: absolute;
  top: 50px;
  left: 20px;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 60px;
  font-weight: 100;
  line-height: 1;
  opacity: 1;
  transform: scale(1);
  transition: 0.25s ease;
  z-index: 999;
}
figure img {
  height: 100%;
  transition: 0.25s;
}

figure h1 {
  font-size: 3rem;
}

.styleForTabs {
  border: none !important;
}

.nav-tabs .nav-item.show .nav-link {
  border: none !important;
}

.nav-link:not(:hover),
.nav-link:hover {
  border: none !important;
  color: black;
}

/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #c0bfbb !important;
} */

#controlled-tab-example-tab-Menu:not(:hover),
#controlled-tab-example-tab-Menu:hover,
#controlled-tab-example-tab-Reviews:not(:hover),
#controlled-tab-example-tab-Reviews:hover,
#controlled-tab-example-tab-Info:not(:hover),
#controlled-tab-example-tab-Info:hover {
  border: none !important;
  color: #58a8c5;
  /* font-size: 10rem;
    padding-left: 2rem; */
}
.dd {
  border-top-style: solid !important;
  border-color: rgb(230, 238, 238) !important;
}
#controlled-tab-example-tab-Menu.nav-link.active,
#controlled-tab-example-tab-Reviews.nav-link.active,
#controlled-tab-example-tab-Info.nav-link.active {
  border: none !important;
  border-bottom: 3px solid #58a8c5 !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active , .nav-tabs .nav-link.active:hover {
  /* border-radius: 0px !important; */
  background-color: rgb(227, 227, 227,0)!important;
  text-decoration: underline !important;
}

.nav-tabs {
  /* --bs-nav-tabs-border-width: 1px; */
  --bs-nav-tabs-border-color: transparent !important;
  /* --bs-nav-tabs-border-radius: 3rem !important; */
  --bs-nav-tabs-link-hover-border-color: red !important;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav {
  --bs-nav-link-padding-x: 3rem !important;
  --bs-nav-link-padding-y: 1.5rem !important;
  --bs-nav-link-font-weight: ;
  /* --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: #58a8c5 !important;
  --bs-nav-link-disabled-color: #58a8c5 !important; */
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link:not(:hover),
.nav-link:hover {
  border: none !important;
  color: #58a8c5 !important;
  font-size: 22px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(195, 181, 159) !important;
}

.fontAR {
  font-size: 1.2rem !important;
  padding: 0 !important;
  text-align: end !important;
}
.h6,
h6 {
  font-size: 0.9rem !important;
}
.color {
  color: white !important;
}

.navbar .fa-stack-2x {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#58a8c5),
    to(#004542)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.2s ease;
}

.navbar .fa-stack-1x {
  color: #ffffff;
  transition: all 0.2s ease;
}

@import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");

@media (max-width: 900px) {
  section h1 {
    font-size: 2rem;
    text-align: center;
  }
  section .text-container {
    flex-direction: column;
  }
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

figure h1 {
  font-size: 3rem;
}

#languageSwitcher:hover {
  border: none !important;
}
a {
  text-decoration: none !important;
}

.scrollDive::-webkit-scrollbar {
  display: none !important;
}
iframe {
  padding: 0 !important;
  --bs-gutter-y: 0 !important;
  --bs-gutter-x: 0 !important;
}

.conttt {
  --bs-gutter-x: 0 !important;
}

.cta-big-image > .container {
  position: relative;
}

.cta-big-image .img-box {
  background: #e81050;
  padding: 3rem;
  position: absolute;
  bottom: -10%;
  right: 10%;
}
@media (max-width: 991.98px) {
  .cta-big-image .img-box {
    position: relative;
    right: auto;
  }
}
.cta-big-image .img-box .sub-title {
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.1em;
  margin-bottom: 1.5rem;
  display: block;
}
.cta-big-image .img-box h2 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1.5rem;
}
.cta-big-image .img-box p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
  margin-bottom: 1.5rem;
}
.cta-big-image .img-box .btn {
  background: #505288;
  border-color: #505288;
  color: #fff;
}
.cta-big-image .img-box *:last-child {
  margin-bottom: 0;
}
.site-section {
  padding: 2.5em 0;
}
@media (min-width: 768px) {
  .site-section {
    padding: 8em 0;
  }
}
.site-section.site-section-sm {
  padding: 4em 0;
}

.site-section-heading {
  padding-bottom: 20px;
  margin-bottom: 0px;
  position: relative;
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .site-section-heading {
    font-size: 3rem;
  }
}
.circle-bg {
  position: relative;
}
.circle-bg:after {
  width: 300px;
  height: 300px;
  content: "";
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  bottom: -90px;
  right: -90px;
  background: #f8f9fa;
}
@media (max-width: 991.98px) {
  .circle-bg:after {
    bottom: 0px;
    right: -10px;
    display: none;
  }
}
@media (max-width: 767.98px) {
  .circle-bg:after {
    display: none;
  }
}

.text-black {
  color: #000 !important;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (max-width: 991.98px) {
  .img-md-fluid {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .display-1,
  .display-3 {
    font-size: 3rem;
  }
}

.play-single-big {
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff !important;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.play-single-big > span {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-40%, -50%);
  -ms-transform: translate(-40%, -50%);
  transform: translate(-40%, -50%);
}
.play-single-big:hover {
  width: 120px;
  height: 120px;
}

.hero-slant {
  background-color: #ccc;
  position: relative;
  background-size: cover;
}
.hero-slant.overlay {
  position: relative;
}
.hero-slant.overlay:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
}
.hero-slant .container {
  position: relative;
}
.hero-slant,
.hero-slant > .container > .row {
  height: 70vh;
}
.hero-slant .sign-up-form {
  padding: 10px;
  border-radius: 7px;
  background: #ffffff;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
}
.hero-slant .sign-up-form .form-control {
  border: none;
}
.hero-slant .sign-up-form .form-control:active,
.hero-slant .sign-up-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hero-slant .sign-up-form input[type="submit"] {
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 7px;
}
.hero-slant .intro {
  position: relative;
  z-index: 2;
  max-width: 500px;
}
.hero-slant .hero_img {
  position: absolute;
  right: 0;
  z-index: 9;
  bottom: 100px;
}
@media (max-width: 991.98px) {
  .hero-slant .hero_img {
    position: relative;
    margin-top: -200px;
    bottom: -100px;
    margin-bottom: 140px;
  }
}
.hero-slant .slant {
  position: absolute !important;
  width: calc(100% + 2px) !important;
  height: 380px !important;
  bottom: -2px !important;
  left: -2px !important;
  z-index: 1 !important;
  background-size: cover !important;
  background-position: left top !important;
  background-repeat: no-repeat !important;
}

.cardimg {
  height: fit-content;
  width: 60%;
  background: #ffffff;
  transition: transform .2s; /* Animation */

}

.cardimg > img:hover {
	transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.cardimg > img {
  width: 100% !important;
  height: 60% !important;
  border: 1px solid lightgray;
  padding: 1rem;
  transition: transform .2s; /* Animation */

}

.cardimg > h2 {
  margin-top: 1rem;
  font-family: "Kalam", cursive;
  font-weight: normal;
  font-size: 2rem;
  color: #4a4a7d;
  text-align: center;
}

.cardimg > p {
  font-family: "Kalam", cursive;
  font-size: 1rem;
  color: #5e5e89;
  text-align: center;
}



.form-check-input:checked {
  background-color: rgb(111, 109, 109,0.3) !important;
  border-color: rgb(88,168,197);
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.form-check-input:focus {
  border-color: rgb(88,168,197) !important;
  outline: 0;
  box-shadow:  rgb(88,168,197,0.25 ) !important;
}


@media (max-width: 900px) {
	section h1 {
	  font-size: 2rem;
	  text-align: center;
	}
	section .text-container {
	  flex-direction: column;
	}
  }
  
  .reveal {
	position: relative;
	opacity: 0;
  }
  
  .reveal.active {
	opacity: 1;
  }
  .active.fade-bottom {
	animation: fade-bottom 1s ease-in;
  }
  .active.fade-left {
	animation: fade-left 1s ease-in;
  }
  .active.fade-right {
	animation: fade-right 1s ease-in;
  }
  @keyframes fade-bottom {
	0% {
	  transform: translateY(50px);
	  opacity: 0;
	}
	100% {
	  transform: translateY(0);
	  opacity: 1;
	}
  }
  @keyframes fade-left {
	0% {
	  transform: translateX(-100px);
	  opacity: 0;
	}
	100% {
	  transform: translateX(0);
	  opacity: 1;
	}
  }
  
  @keyframes fade-right {
	0% {
	  transform: translateX(100px);
	  opacity: 0;
	}
	100% {
	  transform: translateX(0);
	  opacity: 1;
	}
  }