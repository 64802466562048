.navbar-scrolled{
    background-color: white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    
}

.linkStyle{
    text-decoration: none !important;

}


.linkStyle a:hover {color: #05052d  !important; text-decoration: none  !important;}


.bgnav{
    background-color: transparent !important;
    color: white !important;
}
.navbar-toggler.collapsed{
    color: white !important;
}


.bgnav2{
    background-color: rgb(233, 229, 223,0.8) !important;
    color: black !important;
}


.color .coller{

    color: #2A3657 !important;
    text-decoration: none !important;
}

/* h4{
    color: #2A3657 !important;

} */